<template>
  <div class="select-container row mx-1 mb-4">
    <div class="w-75 d-flex align-items-center">
      <b-form-select v-model="selected_student_id" value-field="id" size="sm">
        <b-form-select-option :value="null"
          >Seleccionar Estudiante</b-form-select-option
        >
        <b-form-select-option
          v-for="student in students"
          :key="student.id"
          :value="student.id"
        >
          <span
            v-if="
              evaluator_view &&
              actualTest &&
              startTest(student.id) &&
              !finishTest(student.id) &&
              !isPublichGradeStudent(student.id) &&
              !isDateAfterTest(actualTest.end_time)
            "
          >
            🟡
          </span>
          <span
            v-if="
              evaluator_view &&
              actualTest &&
              startTest(student.id) &&
              !finishTest(student.id) &&
              !isPublichGradeStudent(student.id) &&
              isDateAfterTest(actualTest.end_time)
            "
          >
            🟠
          </span>
          <span
            v-if="
              actualTest &&
              evaluator_view &&
              finishTest(student.id) &&
              !isPublichGradeStudent(student.id)
            "
          >
            🟢
          </span>
          <span
            v-if="
              actualTest && evaluator_view && isPublichGradeStudent(student.id)
            "
            class="checkmark"
          >
            📝
          </span>
          <span v-if="actualTest && evaluator_view && !startTest(student.id)">
            ❌
          </span>
          <span v-if="actualTest && evaluator_view && inGroup(student.id)">
            {{ getNameGroup(student.id) }}
            <span v-if="isLeaderStudent(student.id)">🚩 </span>
            -
          </span>
          {{ student.first_name + " " + student.last_name }}
        </b-form-select-option>
      </b-form-select>
      <InfoModal
        v-if="evaluator_view && actualTest"
        class="ml-1 noprint"
        :tooltip_text="`Presione para poder visualizar la Leyenda de Iconos del Evaluado`"
        :modal_title="`Leyenda de Iconos del Evaluado`"
      >
        <b-table-simple bordered striped>
          <b-thead>
            <b-tr>
              <b-th class="primary-cell">Información</b-th>
              <b-th class="primary-cell text-center">Icono</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td
                >Evaluado que ya inició su
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    false,
                    "Evaluación"
                  )
                }}</b-td
              >
              <b-td class="text-center">🟡</b-td>
            </b-tr>
            <b-tr>
              <b-td
                >Evaluado que comenzó la
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    false,
                    "Evaluación"
                  )
                }}
                pero no la finalizó</b-td
              >
              <b-td class="text-center">🟠</b-td>
            </b-tr>
            <b-tr>
              <b-td
                >Evaluado que ya terminó su
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    false,
                    "Evaluación"
                  )
                }}</b-td
              >
              <b-td class="text-center">🟢</b-td>
            </b-tr>
            <b-tr>
              <b-td
                >Evaluado que aún no ha empezado su
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    false,
                    "Evaluación"
                  )
                }}</b-td
              >
              <b-td class="text-center">❌</b-td>
            </b-tr>
            <b-tr>
              <b-td>Evaluado que ya se le publicó su nota/puntaje</b-td>
              <b-td class="text-center">📝</b-td>
            </b-tr>
            <b-tr>
              <b-td>Lider de Grupo</b-td>
              <b-td class="text-center">🚩</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </InfoModal>
    </div>
    <div class="navigate-items w-25">
      <template v-if="students.length > 0">
        <b-button
          v-if="skip_corrected && availableUnPublished"
          size="sm"
          variant="secondary"
          :disabled="!availablePreviousUserUnCorected"
          @click="previousUserUnCorrected"
          v-b-tooltip.v-secondary.noninteractive="`Anterior sin corregir`"
          class="ml-2 navigate-left"
          :class="{
            'navigate-left-sticky': sticky,
            'navigate-color': availablePreviousUserUnCorected,
          }"
        >
          <b-icon icon="chevron-double-left"></b-icon>
        </b-button>
        <b-button
          size="sm"
          variant="none"
          @click="previousUser"
          v-b-tooltip.v-secondary.noninteractive="`Ir al anterior`"
          class="navigate-left navigate-color"
          :class="{
            'navigate-left-sticky': sticky,
            'ml-2': !skip_corrected || !availableUnPublished,
          }"
        >
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
        <b-button
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="`Ir al siguiente`"
          @click="nextUser"
          class="navigate-right navigate-color"
          :class="{
            'navigate-right-sticky': sticky,
            'mr-2': !skip_corrected || !availableUnPublished,
          }"
        >
          <b-icon icon="chevron-right"></b-icon>
        </b-button>
        <b-button
          v-if="skip_corrected && availableUnPublished"
          size="sm"
          :disabled="!availableNextUserUnCorected"
          variant="secondary"
          @click="nextUserUnCorrected"
          v-b-tooltip.v-secondary.noninteractive="`Siguiente sin corregir`"
          class="navigate-right mr-2"
          :class="{
            'navigate-right-sticky': sticky,
            'navigate-color': availableNextUserUnCorected,
          }"
        >
          <b-icon icon="chevron-double-right"></b-icon>
        </b-button>
      </template>
      <template v-else
        ><b-badge class="badge-no-student ml-2 mr-2" variant="danger"
          >No hay estudiantes seleccionados.</b-badge
        ></template
      >
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudentCarousel",
  components: {
    InfoModal: () => import("@/components/reusable/InfoModal"),
  },
  props: {
    section_id: {
      type: Number,
    },
    current_users: {
      type: Array,
    },
    sticky: {
      type: Boolean,
    },
    actualTest: {
      type: Object,
    },
    skip_corrected: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_student_id: null,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      users: names.USERS,
      evaluatee_tests: names.EVALUATEE_TESTS,
      new_test_groups: names.NEW_TEST_GROUPS,
    }),
    currentNewTestGroups() {
      if (this.actualTest) {
        return this.new_test_groups.filter(
          (x) => x.test === this.actualTest.id
        );
      } else return [];
    },
    section() {
      if (!this.section_id) return [];
      return this.sections.find((x) => x.id == this.section_id);
    },
    students() {
      let list = [];
      if (!this.section_id) {
        if (this.current_users) {
          list = this.users.filter((x) => this.current_users.includes(x.id));
        }
      } else {
        if (!this.section) return list;
        this.section.students.forEach((student_id) => {
          const student = this.users.find((x) => x.id == student_id);
          if (student) list.push(student);
        });
      }
      return list;
    },
    availableNextUserUnCorected() {
      if (this.selected_student_id == null && this.students.length > 0)
        return true;
      const current_index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (
        this.students.some((x, index) => {
          if (index > current_index)
            if (!this.isPublichGradeStudent(x.id)) return true;
        })
      )
        return true;
      else return false;
    },
    availablePreviousUserUnCorected() {
      if (this.selected_student_id == null && this.students.length > 0)
        return true;
      const current_index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (
        this.students.some((x, index) => {
          if (index < current_index)
            if (!this.isPublichGradeStudent(x.id)) return true;
        })
      )
        return true;
      else return false;
    },
    availableUnPublished() {
      if (!this.actualTest) return false;
      const students_ids = this.students.map((x) => x.id);
      return this.evaluatee_tests.some(
        (x) =>
          x.test === this.actualTest.id &&
          students_ids.includes(x.evaluatee) &&
          x.is_published == false
      );
    },
  },
  methods: {
    isDateAfterTest(end_time) {
      const now = new this.$moment();
      const endDateTest = this.$moment(end_time);

      return now.isAfter(endDateTest);
    },
    isPublichGradeStudent(user_id) {
      if (!this.actualTest) return false;
      return this.evaluatee_tests.some(
        (x) =>
          x.test === this.actualTest.id &&
          x.evaluatee == user_id &&
          x.is_published == true
      );
    },
    startTest(user_id) {
      if (!this.actualTest) return false;
      return this.evaluatee_tests.some(
        (x) =>
          x.test == this.actualTest.id &&
          x.evaluatee == user_id &&
          x.start_time != null
      );
    },
    finishTest(user_id) {
      if (!this.actualTest) return false;
      return this.evaluatee_tests.some(
        (x) =>
          x.test == this.actualTest.id &&
          x.evaluatee == user_id &&
          x.is_finished == true
      );
    },
    inGroup(user_id) {
      if (!this.actualTest) return false;
      return this.currentNewTestGroups.some((x) =>
        x.students.includes(user_id)
      );
    },
    isLeaderStudent(user_id) {
      if (!this.actualTest) return false;
      return this.currentNewTestGroups.some((x) => x.leader == user_id);
    },
    getNameGroup(user_id) {
      if (!this.actualTest) return false;
      const test_group = this.currentNewTestGroups.find((x) =>
        x.students.includes(user_id)
      );
      return test_group ? test_group.name : "";
    },
    previousUser() {
      if (this.students.length == 0) return;
      if (!this.selected_student_id) {
        this.selected_student_id = this.students[this.students.length - 1].id;
        return;
      }
      const index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (index != -1 && index == 0) {
        this.selected_student_id = this.students[this.students.length - 1].id;
        return;
      } else if (this.students.length > 0) {
        this.selected_student_id = this.students[index - 1].id;
        return;
      }
    },
    nextUser() {
      if (this.students.length == 0) return;
      if (!this.selected_student_id && this.students.length > 0) {
        this.selected_student_id = this.students[0].id;
        return;
      }
      const index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (index != -1 && index < this.students.length - 1) {
        this.selected_student_id = this.students[index + 1].id;
        return;
      } else if (this.students.length > 0) {
        this.selected_student_id = this.students[0].id;
        return;
      }
    },
    nextUserUnCorrected() {
      if (this.students.length == 0 && this.availableNextUserUnCorected) return;
      if (!this.selected_student_id && this.students.length > 0) {
        this.selected_student_id = this.students[0].id;
        return;
      }
      const index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (index != -1 && index < this.students.length - 1) {
        for (let i = index + 1; i < this.students.length; i++) {
          if (
            this.students[i] &&
            !this.isPublichGradeStudent(this.students[i].id)
          ) {
            this.selected_student_id = this.students[i].id;
            return;
          }
          if (i + 1 == this.students.length) {
            this.selected_student_id = this.students[0].id;
            return;
          }
        }
      } else if (this.students.length > 0) {
        this.selected_student_id = this.students[0].id;
        return;
      }
    },
    previousUserUnCorrected() {
      if (this.students.length == 0) return;
      if (!this.selected_student_id) {
        this.selected_student_id = this.students[this.students.length - 1].id;
        return;
      }
      const index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (index != -1 && index == 0) {
        this.selected_student_id = this.students[this.students.length - 1].id;
        return;
      } else if (this.students.length > 0) {
        for (let i = index - 1; i >= 0; i--) {
          if (
            this.students[i] &&
            !this.isPublichGradeStudent(this.students[i].id)
          ) {
            this.selected_student_id = this.students[i].id;
            return;
          }
        }
      }
    },
  },
  watch: {
    selected_student_id() {
      this.$emit("student_selcted", this.selected_student_id);
    },
  },
  created() {
    if (this.section_id) {
      this.$store
        .dispatch(names.FETCH_SECTION, this.section_id)
        .then((section) => {
          // this.selected_student_id = section.students[0];
          this.$store.dispatch(names.FETCH_ALT_USERS, {
            user_ids: section.students,
          });
        });
    }
    if (this.current_users) {
      // this.selected_student_id = this.current_users[0];
      this.$store.dispatch(names.FETCH_ALT_USERS, {
        user_ids: this.current_users,
      });
    }
  },
};
</script>

<style scoped>
.primary-cell {
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.checkmark {
  color: green;
  font-size: 18px; /* Ajusta el tamaño según sea necesario */
}
.badge-no-student {
  display: flex;
  align-items: center;
}
.select-container {
  width: 100%;
}
.navigate-left,
.navigate-left-sticky,
.navigate-right,
.navigate-right-sticky {
  color: #fff;
  padding-inline: 1.5em;
}
.navigate-color {
  background-color: var(--kl-primary-button-color) !important;
}
.navigate-items {
  display: flex;
}
.navigate-color:hover {
  background: var(--kl-primary-button-hover-color) !important;
}

.navigate-left,
.navigate-right {
  width: 100%;
}
.navigate-left {
  margin-right: 0.5em;
}
.navigate-right {
  margin-left: 0.5em;
}

.navigate-left-sticky,
.navigate-right-sticky {
  width: 100%;
}
@media (max-width: 991px) {
  .student-carousel-sticky > .select-container {
    width: 80%;
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
}
</style>